<template>
    <div class="error-page">
        <div class="wrapper">
            <img src="@common/assets/sorry.webp" alt="I am sorry!">
            <div>
                <div class="error-code">{{ error_code }}</div>
                <h1 class="error-message">{{ message }}</h1>
                <a class="btn btn-success" href="/">{{ $t('controls.go-to-homepage') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n';
export default {
    name: "ErrorPage",
    props: {
        error_code: {
            type: String,
            default: () => '404',
        }
    },
    setup(props) {
        const i18n = useI18n();

        const message = computed(() => {
            switch(props.error_code){
                case '404':
                    return i18n.t('error.page-not-found');
                default:
                    return i18n.t('error.an-error-has-occured')
            }
        });

        return {
            message
        }
    }
}
</script>

<style scoped>
.error-page{
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    width: 100%;
    min-width: 50vw;
}
.wrapper{
    margin: auto;
    padding: 2rem 1rem;
    /* width: 40%; */
    background: white;
    box-shadow: 1px 2px 8px 1px var(--border-color);
    font-size: 0.8rem;
    max-width: 35rem;
    display: flex;
    align-items: center;
}
.error-code{
    font-size: 5rem;
    font-weight: 700;
}
.wrapper>img{
    width: 50%;
    margin: 1rem;
}
.wrapper .btn{
    font-size: 1rem;
}

@media screen and (max-width: 800px) {
    .wrapper {
        flex-direction: column;
        text-align: center; 
    }
    .wrapper>img{
        width: calc(100% - 4rem);
    }
}
</style>
